import { database } from '../firebaseConfig';

export const handlePhoneChange = (setPhone, event) => {
    const value = event.target.value.replace(/\D/g, ''); // Remove non-digits
    let formattedValue = '';

    // Formatting logic
    if (value.length > 0) {
        formattedValue += value.substring(0, 3);
    }
    if (value.length > 3) {
        formattedValue += '-' + value.substring(3, 6);
    }
    if (value.length > 6) {
        formattedValue += '-' + value.substring(6, 10);
    }

    setPhone(formattedValue);
};

export const handleNameChange = (setName, event) => {
    const value = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
        setName(value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '));
    }
};

export const handleEmailChange = (setEmail, event) => {
    const value = event.target.value;
    setEmail(value);  // Just set the value without validation to allow user typing
};

export const handlePostalChange = (setPostalCode, event) => {
    const value = event.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');
    if (value.length <= 6) {
        setPostalCode(value.toLowerCase());
    }
};

export const handleZipChange = (setZip, event) => {
    const value = event.target.value.replace(/\D/g, ''); // Remove non-digits
    if (value.length <= 5) {
        setZip(value);
    }
};

export const handleMessageChange = (setMessage, event) => {
    const value = event.target.value.replace(/[^a-zA-Z0-9 .,]/g, '');
    setMessage(value);
};

export const handleSubmit = async (formState, cityData, onSuccess, onError) => {
    const { name, phone, email, postalCode, zip, zipTo, message } = formState;  // Add zipTo

    // Basic email validation before submitting
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
        return onError(new Error("Invalid email format"));
    }

    const currentTime = new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

    try {
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const ipData = await ipResponse.json();
        const userIp = ipData.ip;

        const newLead = {
            date: new Date().toISOString().split('T')[0],
            email: email || "Unavailable",
            phone: phone,
            zip: postalCode || zip,
            zipTo: zipTo || "",  // Include zipTo in the new field
            source: 'Paid Ads',
            brand_id: 1,
            status: "new",
            type: "moving",
            won_by: "",
            location_id: cityData.locationId || '',
            name: name,
            time: currentTime,
            note_from_customer: message,
            ip_address: userIp,
        };

        await database.ref('leads').push(newLead);
        onSuccess();
    } catch (error) {
        onError(error);
    }
};
