import React, { useEffect, useRef, useCallback } from 'react';
import { loadGoogleMapsScript } from '../../utils/googleMapScript';

const Step1 = ({ formik, nextStep }) => {
  const { values, errors, touched, setFieldValue, validateForm, setTouched } = formik;

  // References for the autocomplete inputs
  const startZipRef = useRef(null);
  const destinationZipRef = useRef(null);

  // Initialize the Google Maps autocomplete fields
  const initAutocomplete = useCallback(() => {
    if (startZipRef.current && destinationZipRef.current) {
      const autocompleteOptions = {
        componentRestrictions: { country: ['us', 'ca'] }, // Restrict to US and Canada
        fields: ['address_components', 'geometry'],
        types: ['geocode'], // Allows addresses and postal codes
      };

      const startAutocomplete = new window.google.maps.places.Autocomplete(startZipRef.current, autocompleteOptions);
      const destinationAutocomplete = new window.google.maps.places.Autocomplete(destinationZipRef.current, autocompleteOptions);

      // Listen for place changes in both fields
      startAutocomplete.addListener('place_changed', () => {
        const place = startAutocomplete.getPlace();
        if (place.geometry) {
          setFieldValue('startZip', place.geometry.location);
          const state = place.address_components.find(component => component.types.includes('administrative_area_level_1')).short_name;
          setFieldValue('startState', state); // Save the state/province
        }
      });
      
      destinationAutocomplete.addListener('place_changed', () => {
        const place = destinationAutocomplete.getPlace();
        if (place.geometry) {
          setFieldValue('destinationZip', place.geometry.location);
          const state = place.address_components.find(component => component.types.includes('administrative_area_level_1')).short_name;
          setFieldValue('destinationState', state); // Save the state/province
        }
      });      
    }
  }, [setFieldValue]);

  // Load Google Maps script when the component mounts
  useEffect(() => {
    loadGoogleMapsScript(initAutocomplete);
  }, [initAutocomplete]);

  // Handle Next button click and validate form
  const handleNextClick = async () => {
    setTouched({
      startZip: true,
      destinationZip: true,
      moveType: true,
      date: true,
    });

    const errors = await validateForm();

    if (Object.keys(errors).length === 0) {
      calculateDistance();
      nextStep();
    }
  };

  // Calculate distance between start and destination locations
  const calculateDistance = () => {
    const { startZip, destinationZip } = values;

    if (startZip && destinationZip) {
      const service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [startZip],
          destinations: [destinationZip],
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK' && response.rows[0].elements[0].status === 'OK') {
            const distanceInMiles = response.rows[0].elements[0].distance.value / 1609.34; // Convert meters to miles
            console.log('Distance in miles:', distanceInMiles);
            setFieldValue('distanceInMiles', distanceInMiles); // Add this line to store distance
          } else {
            console.error('Error calculating distance:', status);
          }
        }
      );
    }
  };

  return (
    <div className="wizard-step">
      <h2>Get a <span>free moving quote</span> now:</h2>
      <form>
        <div className="row">
          <div className="col">
            <label>Starting Location</label>
            <input 
              type="text" 
              name="startZip" 
              ref={startZipRef} // Reference to the autocomplete input
              className={touched.startZip && errors.startZip ? 'error' : ''}
              placeholder="Enter starting location"
              onChange={(e) => setFieldValue('startZip', e.target.value)} // Allow manual input
            />
            {touched.startZip && errors.startZip && <p className="error-text">{errors.startZip}</p>}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label>Destination Location</label>
            <input 
              type="text" 
              name="destinationZip" 
              ref={destinationZipRef} // Reference to the autocomplete input
              className={touched.destinationZip && errors.destinationZip ? 'error' : ''}
              placeholder="Enter destination location"
              onChange={(e) => setFieldValue('destinationZip', e.target.value)} // Allow manual input
            />
            {touched.destinationZip && errors.destinationZip && <p className="error-text">{errors.destinationZip}</p>}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label>Property Type</label>
            <select name="moveType" onChange={formik.handleChange} value={values.moveType}>
              <option value="">Select type:</option>
              <option value="House">House</option>
              <option value="Apartment/Condo">Apartment/Condo</option>
              <option value="Business">Business</option>
            </select>
            {touched.moveType && errors.moveType && <p className="error-text">{errors.moveType}</p>}
          </div>

          <div className="col">
            <label>Move Date</label>
            <select name="date" onChange={formik.handleChange} value={values.date}>
              <option value="">Select move date:</option>
              <option value="Within 4 weeks">Within 4 weeks</option>
              <option value="In 1-2 months">In 1-2 months</option>
              <option value="In 3+ months">In 3+ months</option>
            </select>
            {touched.date && errors.date && <p className="error-text">{errors.date}</p>}
          </div>
        </div>

        <div className="button-container">
          <p>&nbsp;</p>
          <button className="next-button" type="button" onClick={handleNextClick}>Next</button>
        </div>
      </form>
    </div>
  );
};

export default Step1;
