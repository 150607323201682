import React from 'react';
import HeroFormLander from './HeroFormLander';
import heroVideo from '../../assets/videos/hero-background.mp4';
import heroFallback from '../../assets/images/backgrounds/additional-services-two-bg.jpg'; // Add a fallback image

function HeroLander({ cityData, locationInfo }) {
    return (
        <section className="main-slider"
            style={{ 
                position: 'relative', 
                overflow: 'hidden', 
                width: '100%',
                background: `none`,
                display: 'flex',
                alignItems: 'center', 
                justifyContent: 'center' 
            }}
        >
            {/* Background Video */}
            <div>
                <video
                    className="hero-video"
                    autoPlay
                    loop
                    muted
                    playsInline
                    poster={heroFallback}  // Fallback image in case the video is not supported
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the video covers the entire area
                        zIndex: -2, // Place the video further back
                    }}
                >
                    <source src={heroVideo} type="video/mp4" />
                    {/* Hide the unsupported message to avoid unnecessary display */}
                    <img src={heroFallback} alt="Fallback background" style={{ display: 'none' }} />
                </video>

                {/* Gradient Overlay */}
                <div
                    className="gradient-overlay"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'black',
                        opacity: '.6',
                        zIndex: -1, // Place the overlay between the video and content
                    }}
                ></div>
            </div>
            {/* Main Content Container */}
            <div className="container" style={{ zIndex: 0 }}>
                <div className="row" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <div className="col-xl-7 col-lg-5">
                        <div className="main-slider__left" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <div className="main-slider__title-box">
                                <span className="main-slider__sub-title">Local movers</span>
                                <h2 className="main-slider__title oswald-font">
                                    <span 
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '4.5rem',
                                        }}
                                    >
                                        {cityData.locationName}'s Top Rated
                                    </span>
                                    <br />
                                    <span 
                                        style={{
                                            color: '#f7c600',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Moving Company
                                    </span>
                                </h2>
                                <ul className="additional-services-two__points list-unstyled">
                                    <li>
                                        <div className="text">
                                            <p
                                                style={{
                                                    color: 'white',
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontSize: '32px',
                                                    fontWeight: 300,
                                                    lineHeight: '1.3em',
                                                }}
                                            >
                                                👉 Moving a house, apartment or business in or around {cityData.locationName}? We've got you!
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-7"
                        style={{
                            margin: '50px 0',
                        }}
                    >
                        <div className="main-slider__right" style={{ display: 'flex', alignItems: 'center', height: '100%', paddingTop: '0' }}>
                            <div className="main-slider__form-box">
                                <h2>Want a free estimate?</h2>
                                <h3>Get your free quote:</h3>
                                <HeroFormLander
                                    cityData={cityData}
                                    locationInfo={locationInfo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HeroLander;
