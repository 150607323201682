import React from 'react';

const Step2 = ({ nextStep, prevStep, handleChange, formData = {} }) => {
  const { rooms = '', squareFootage = '', description = '', moveType = '', distanceInMiles = '' } = formData;

  return (
    <div className="wizard-step">
      <h2>Step 2: Move Details</h2>
      <p>Distance in Miles: {distanceInMiles ? distanceInMiles.toFixed(2) : 'Calculating...'}</p>
      <label>Number of Bedrooms (House/Apartment):</label>
      <select
        value={rooms}
        onChange={handleChange('rooms')}
      >
        <option value="">Select number of bedrooms:</option>
        <option value="1">1 bedroom</option>
        <option value="2">2 bedrooms</option>
        <option value="3">3 bedrooms</option>
        <option value="4">4 bedrooms</option>
        <option value="5">5 bedrooms</option>
        <option value="6">6 bedrooms</option>
        <option value="7">7 bedrooms</option>
        <option value="8+">8+ bedrooms</option>
      </select>

      {/* Conditionally show Square Footage input if moveType is Business */}
      {moveType === 'Business' && (
        <>
          <label>Square Footage (Business):</label>
          <input
            type="number"
            value={squareFootage}
            onChange={handleChange('squareFootage')}
            placeholder="Enter square footage"
          />
        </>
      )}

      <label>Move Description:</label>
      <textarea
        value={description}
        onChange={handleChange('description')}
        placeholder="Provide a brief description"
      ></textarea>

      <div className="button-container">
        <button className="outline-button" onClick={prevStep}>Back</button>
        <button className="next-button" onClick={nextStep}>Next</button>
      </div>
    </div>
  );
};

export default Step2;
