import React from 'react';
import HeaderSlim from '../components/Header/HeaderSlim';
import HeroLander from '../components/Landing/HeroLander';
import HowItWorksSection from '../components/HowItWorks/HowItWorksSection';
import YellowCta from '../components/CTA/YellowCta';
import ServicesSection from '../components/Services/ServicesSection';
import WideCtaSection from '../components/CTA/WideCtaSection';
import AboutUs from '../components/Landing/AboutUs';
import BadgeSection from '../components/Badges/BadgeSection';
import RequestQuote from '../components/CTA/RequestQuote';
import SocialProof from '../components/SocialProof/SocialProof';
import ExitIntentPopup from '../components/ExitIntentPopup/ExitIntentPopup';
import Footer from '../components/Footer/Footer';

function LandingPage({ cityData, locationInfo }) {
  // Extract the cities from cityData or provide a fallback default
  const cities = cityData?.cities || ['none']; // Provide fallback if cities is undefined

  return (
    <div>
        <HeaderSlim 
          cityData={cityData}
          locationInfo={locationInfo}
        />
        <HeroLander
        cityData={cityData}
        locationInfo={locationInfo}
        />
        <YellowCta
          cityData={cityData}
          locationInfo={locationInfo}
        />
        <ServicesSection
          cityData={cityData}
          locationInfo={locationInfo}
        />
        <WideCtaSection
          cityData={cityData}
          locationInfo={locationInfo}
        />
        <HowItWorksSection 
          cityData={cityData}
          locationInfo={locationInfo}
        />
        <AboutUs
          cityData={cityData}
          locationInfo={locationInfo}
        />
        <RequestQuote 
          cityData={cityData}
          locationInfo={locationInfo}
        />
        <BadgeSection
          cityData={cityData}
          locationInfo={locationInfo}
        />
        <SocialProof
          cityData={cityData}
          locationInfo={locationInfo}
          cities={cities}
        />
        <Footer 
          cityData={cityData}
          locationInfo={locationInfo}
        />
        <ExitIntentPopup />
    </div>
  );
}

export default LandingPage;