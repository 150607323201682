import React, { useState, useEffect } from 'react';

const names = [
  'Arjun', 'Zara', 'Mateo', 'Aisha', 'Liam', 'Mei', 'Jamal', 'Sofia', 'Jin', 'Anika',
  'Omar', 'Kavya', 'Ethan', 'Isabella', 'Kwame', 'Ravi', 'Elena', 'Isaac', 'Yara', 'Noah',
  'Priya', 'Amara', 'Malik', 'Sarah', 'Keisha', 'Levi', 'Nina', 'Wei', 'Hassan', 'Kiran',
  'Sebastian', 'Nia', 'Dae', 'Ahmed', 'Tara', 'Samuel', 'Emilia', 'Chinwe', 'Raj', 'Maya',
  'David', 'Zhou', 'Kim', 'Olivia', 'Nelson', 'Joshua', 'Amir', 'Lina', 'Jasper', 'Chen'
];

const times = ['Just Now', '10 minutes ago', '30 minutes ago', '1 hour ago', '2 hours ago', '3 hours ago', '4 hours ago', '5 hours ago', '6 hours ago', '7 hours ago'];

const SocialProof = ({ cities }) => {
  const [visible, setVisible] = useState(false);
  const [notification, setNotification] = useState({ name: '', city: '', time: '' });

  useEffect(() => {
    const getRandomItem = (arr) => arr[Math.floor(Math.random() * arr.length)];

    const generateRandomNotification = () => {
      const randomName = getRandomItem(names);
      const randomCity = getRandomItem(cities); // Use cities from props
      const randomTime = getRandomItem(times);
      return { name: randomName, city: randomCity, time: randomTime };
    };

    const initialTimeout = setTimeout(() => {
      setNotification(generateRandomNotification());
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    }, 6000);

    const interval = setInterval(() => {
      setNotification(generateRandomNotification());
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    }, 22000);

    return () => {
      clearTimeout(initialTimeout);
      clearInterval(interval);
    };
  }, [cities]); // No need to include names or times in the dependency array

  const handleClick = () => {
    setVisible(false);
  };

  return (
    visible && (
      <div style={styles.notification} onClick={handleClick}>
        <div style={styles.rocket}>🚀</div>
        <div style={styles.textContainer}>
          <div>
            <span style={styles.name}>{notification.name} </span>
            <span>requested service in </span>
            <strong>{notification.city}!</strong>
          </div>
          <div style={styles.time}>{notification.time}</div>
        </div>
      </div>
    )
  );
};

const styles = {
  notification: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    background: 'linear-gradient(45deg, #6a11cb, #2575fc)',
    color: '#fff',
    borderRadius: '12px',
    padding: '15px 20px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    minWidth: '250px',
    maxWidth: '300px',
    cursor: 'pointer',
  },
  rocket: {
    fontSize: '30px',
    marginRight: '10px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  name: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    marginBottom: '5px',
  },
  time: {
    marginTop: '5px',
    fontSize: '1.1rem',
    opacity: 0.8,
  },
};

export default SocialProof;
