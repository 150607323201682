import React from 'react';

function YellowCta({ cityData, locationInfo }) {
    return (
        <section className="row emmergency-leaks"
            style={{
                backgroundColor: '#FBE108',
                position: "relative",
            }}
        >
            {/* Repeating border separator */}
            <div 
                style={{
                    width: "100%",
                    height: "10px", // Height of the separator
                    backgroundImage: "repeating-linear-gradient(45deg, transparent 0, transparent 18px, #22448F 18px, #22448F 20px)",
                    margin: "15px 0", // Space between the separator and the content
                }}
            ></div>
            <div className="container">
                {/* <h4 className="this-intro">If you have questions for us,</h4> */}
                <h2 className="this-title"
                    style={{
                        color: "#4E4E4E",
                        fontFamily: "'Oswald', sans-serif",
                        fontSize: "36px",
                        fontWeight: 700,
                        textTransform: "uppercase"
                      }}                      
                >
                    FULL-SERVICE MOVERS - RESIDENTIAL &amp; COMMERCIAL
                </h2>
                <div 

                    style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                >
                    <p
                        style={{
                            textAlign: "center",
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: "24px",
                            fontWeight: 400,
                            lineHeight: "1.4em",
                            width: "65%",
                        }}                      
                    >
                        <span>
                        From packing and moving to storage & much more, we know what it takes to get you ready to go - and into your new home or office quickly and efficiently.
                        </span>
                    </p>
                </div>
            </div>
            {/* Triangle element using clip-path */}
            <div 
                style={{
                    width: "80px", // Width of the triangle base
                    height: "30px", // Height of the triangle
                    backgroundColor: "#FBE108", // Triangle color
                    position: "absolute",
                    bottom: "-30px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    clipPath: "polygon(50% 100%, 0 0, 100% 0)", // Creates the triangle shape
                    zIndex: 1,
                }}
            ></div>
        </section>
    );
}

export default YellowCta;