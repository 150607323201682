import React, { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Step1 from './Step1';
import Step2 from './Step2';
import Step4 from './Step4';
import Step5 from './Step5';
import ProgressBar from './ProgressBar';
import './Wizard.css';

const Wizard = () => {
  const [step, setStep] = useState(1);
  const [loadingText, setLoadingText] = useState('');

  // Update schema to require startZip and destinationZip but without format validation
  const formSchema = yup.object({
    startZip: yup.string().required('Starting location is required'),
    destinationZip: yup.string().required('Destination location is required'),
    moveType: yup.string().required('Move Type is required'),
    date: yup.string().required('Date is required'),
  });

  const formik = useFormik({
    initialValues: {
      startZip: '', // Required but no specific format
      destinationZip: '', // Required but no specific format
      moveType: '',
      date: '',
      rooms: '',
      squareFootage: '',
      description: '',
      name: '',
      phone: '',
      email: '',
      country: 'US',
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      formik.setTouched({
        startZip: true,
        destinationZip: true,
        moveType: true,
        date: true,
      });
      if (formik.isValid) {
        nextStep();
      }
    },
  });

  // Memoize nextStep to prevent re-creation on every render
  const nextStep = useCallback(() => {
    setStep((prevStep) => prevStep + 1);
  }, []);

  const prevStep = () => {
    setStep(step - 1);
  };

  // Memoize simulateLoading to avoid re-creation on every render
  const simulateLoading = useCallback(() => {
    const messages = ['Checking availability...', 'Looking for movers...', 'Finalizing options...'];
    let index = 0;

    const interval = setInterval(() => {
      setLoadingText(messages[index]);
      index++;

      if (index === messages.length) {
        clearInterval(interval);
        nextStep();
      }
    }, 3000);
  }, [nextStep]);

  useEffect(() => {
    if (step === 3) {
      simulateLoading();
    }
  }, [step, simulateLoading]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 nextStep={nextStep} formik={formik} />;
      case 2:
        return <Step2 nextStep={nextStep} prevStep={prevStep} formData={formik.values} handleChange={formik.handleChange} />;
      case 3:
        return (
          <div className="loader">
            <div className="loader-spinner"></div>
            <p className="loader-text">{loadingText}</p>
          </div>
        );
      case 4:
        return <Step4 nextStep={nextStep} prevStep={prevStep} formik={formik} />;
      case 5:
        return <Step5 prevStep={prevStep} formik={formik} />;
      default:
        return <Step1 nextStep={nextStep} formik={formik} />;
    }
  };

  return (
    <div className="wizard-container">
      <ProgressBar step={step} />
      {renderStep()}
    </div>
  );
};

export default Wizard;
