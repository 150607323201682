import React from 'react';
import HeaderSlim from '../components/Header/HeaderSlim';
import Wizard from '../components/Wizard/Wizard';

function Home({ locationInfo }) {
  return (
    <div>
        <HeaderSlim 
          cityData='18689'
          locationInfo='18689'
        />
        <Wizard />
    </div>
  );
}

export default Home;