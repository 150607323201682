import React, { useContext, useEffect, useState } from 'react';
import { useParams, Routes, Route, Navigate } from 'react-router-dom';
import CurrentCityContext from './CurrentCityContext'; // Adjust the path as necessary
import cityConfig from './cityConfig'; // Adjust the path as necessary
import BookNow from './pages/BookNow';
// import City from './pages/City';
import Faq from './pages/FAQ';
import Quote from './pages/Quote';
import HowItWorks from './pages/HowItWorks';
import Reviews from './pages/Reviews';
import WhatWeTake from './pages/WhatWeTake';
import LandingPage from './pages/LandingPage';
// import Header from './components/Header/Header';
// import Footer from './components/Footer/Footer';
import { database } from './firebaseConfig';
import SocialProof from './components/SocialProof/SocialProof';

function CityLandingPage() {
    const { city } = useParams();
    const { setCurrentCity } = useContext(CurrentCityContext); // Accessing the context updater
    const cityData = cityConfig[city];
    const [locationInfo, setLocationInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {
        setCurrentCity(city);

        if (cityData && cityData.locationId) {
            setIsLoading(true); // Start loading
            const locationRef = database.ref(`locations/${cityData.locationId}`);
            locationRef.once('value', (snapshot) => {
                const data = snapshot.val();
                setLocationInfo(data);
                setIsLoading(false); // Stop loading after data is fetched
            });
        } else {
            setIsLoading(false); // Stop loading if cityData is not available
        }
    }, [city, cityData, setCurrentCity]);

    if (!cityData) {
        return <Navigate to="/" />;
    }

    if (isLoading) {
        return ;
    }

    return (
        <div>
            {/* <Header locationInfo={locationInfo} /> */}
            <Routes>
                <Route 
                    path="/" 
                    element={<LandingPage city={city} cityData={cityData} locationInfo={locationInfo} />} 
                />
                {/* <Route 
                    path="/" 
                    element={<City city={city} cityData={cityData} locationInfo={locationInfo} />} 
                /> */}
                <Route 
                    path="book-now" 
                    element={<BookNow 
                        city={city} 
                        cityData={cityData} 
                        locationInfo={locationInfo}
                    />} 
                />
                <Route 
                    path="faq" 
                    element={<Faq city={city} 
                        cityData={cityData} 
                        locationInfo={locationInfo}
                    />} 
                />
                <Route 
                    path="get-quote" 
                    element={<Quote 
                        city={city} 
                        cityData={cityData}
                        locationInfo={locationInfo} 
                    />} 
                />
                <Route 
                    path="how-it-works" 
                    element={<HowItWorks 
                        city={city} 
                        cityData={cityData}
                        locationInfo={locationInfo}
                    />} 
                />
                <Route 
                    path="reviews" 
                    element={<Reviews 
                        city={city} 
                        cityData={cityData}
                        locationInfo={locationInfo}
                    />} 
                />
                <Route 
                    path="what-we-take" 
                    element={<WhatWeTake
                        city={city} 
                        cityData={cityData}
                        locationInfo={locationInfo}
                    />} 
                />
                <Route 
                    path="hire" 
                    element={<LandingPage
                        city={city} 
                        cityData={cityData}
                        locationInfo={locationInfo}
                    />} 
                />
            </Routes>
            <SocialProof cities={cityData.cities || []} /> {/* Pass cities from cityData */}
            {/* <Footer cityData={cityData} locationInfo={locationInfo} /> */}
        </div>
    );
}

export default CityLandingPage;