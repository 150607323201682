import React from 'react';
import { Link } from 'react-router-dom';

function WideCtaSection({ cityData, locationInfo }) {
    return (
        <section 
            className="row emmergency-leaks"
            style={{
                backgroundColor: '#00529c',
                position: "relative",
                padding: "30px 10px",
            }}
        >
            <div className="container">
                {/* <h4 className="this-intro">If you have questions for us,</h4> */}
                <h2 
                    className="this-title"
                    style={{
                        fontFamily: '"Oswald", Sans-serif',
                        color: "rgb(251, 225, 8)",
                    }}
                >
                    PLANNING A MOVE CAN BE COMPLICATED
                </h2>
                <p
                    style={{
                        textAlign: "center",
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "24px",
                        fontWeight: 400,
                        lineHeight: "1.4em",
                        color: "white",
                        padding: "0 10%",
                    }}
                >
                    Our team at UltaMovers will give you professional moving options and provide you a free moving estimate. 
                    <br />
                    <br />
                    It's as simple as calling us today:
                </p>
                <br />
                <Link 
                    className="call-link" 
                    to={'tel:'+locationInfo.tracking_phone}
                    style={{
                        backgroundColor: "rgb(251, 225, 8)",
                        color: "#22448F",
                        padding: "15px 30px",
                        borderRadius: "5px",
                        fontSize: "24px",
                        fontWeight: 700,
                        fontFamily: "'Roboto', sans-serif",
                        display: "inline-block",
                        textDecoration: "none",
                    }}
                >
                    Call {locationInfo.tracking_phone}
                </Link>
            </div>
        </section>
    );
}

export default WideCtaSection;