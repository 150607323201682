import React, { useState } from 'react';

const Step4 = ({ prevStep, nextStep, formik }) => {
  const [selectedServices, setSelectedServices] = useState([]);

  const handleServiceChange = (service) => {
    if (selectedServices.includes(service)) {
      setSelectedServices(selectedServices.filter(s => s !== service));
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  return (
    <div className="wizard-step">
      <h2>Step 4: Additional Services</h2>
      <p>
        Select any additional services you may need for your move:
      </p>
      <div className="service-options">
        <label className={`service-box ${selectedServices.includes('Packing Services') ? 'selected' : ''}`}>
          <input
            type="checkbox"
            name="services"
            value="Packing Services"
            checked={selectedServices.includes('Packing Services')}
            onChange={() => handleServiceChange('Packing Services')}
          />
          <span className="checkbox-icon">✔</span>
          <span className="service-text">Packing Services</span>
        </label>

        <label className={`service-box ${selectedServices.includes('Furniture Assembly') ? 'selected' : ''}`}>
          <input
            type="checkbox"
            name="services"
            value="Furniture Assembly"
            checked={selectedServices.includes('Furniture Assembly')}
            onChange={() => handleServiceChange('Furniture Assembly')}
          />
          <span className="checkbox-icon">✔</span>
          <span className="service-text">Furniture Assembly</span>
        </label>

        <label className={`service-box ${selectedServices.includes('Storage') ? 'selected' : ''}`}>
          <input
            type="checkbox"
            name="services"
            value="Storage"
            checked={selectedServices.includes('Storage')}
            onChange={() => handleServiceChange('Storage')}
          />
          <span className="checkbox-icon">✔</span>
          <span className="service-text">Furniture Storage</span>
        </label>
      </div>

      <div className="button-container">
        <button className="outline-button" onClick={prevStep}>Back</button>
        <button className="next-button" onClick={nextStep}>Next</button>
      </div>
    </div>
  );
};

export default Step4;
