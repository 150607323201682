const cityConfig = {
    toronto: {
        locationId: 13929,
        locationName: 'Toronto',
        badgeGroup: 'ontario',
        cities: ['Toronto', 'Mississauga', 'Brampton', 'Markham', 'Vaughan', 'Richmond Hill', 'Oakville', 'Burlington', 'Milton', 'Caledon'],
    },

    miami: {
        locationId: 18689,
        locationName: 'Miami',
        badgeGroup: 'florida',
        cities: ['Miami', 'Hollywood', 'Pompano Beach', 'Coral Gables', 'Miami Beach', 'Kendall', 'Hialeah', 'Pembroke Pines', 'Miami Gardens', 'Aventura']
    },
    
    kitchener: {
        locationId: 15585,
        locationName: 'Kitchener-Waterloo',
        badgeGroup: 'ontario',
        cities: ['Kitchener', 'Waterloo', 'Cambridge', 'Guelph', 'Brantford'],
    },

    waterloo: {
        locationId: 15585,
        locationName: 'Kitchener-Waterloo',
        badgeGroup: 'ontario',
        cities: ['Kitchener', 'Waterloo', 'Cambridge', 'Guelph', 'Brantford'],
    },

    cambridge: {
        locationId: 15585,
        locationName: 'Cambridge',
        badgeGroup: 'ontario',
        cities: ['Kitchener', 'Waterloo', 'Cambridge', 'Guelph', 'Brantford'],
    },

    guelph: {
        locationId: 15585,
        locationName: 'Guelph',
        badgeGroup: 'ontario',
        cities: ['Kitchener', 'Waterloo', 'Cambridge', 'Guelph', 'Brantford'],
    },

    brantford: {
        locationId: 15585,
        locationName: 'Brantford',
        badgeGroup: 'ontario',
        cities: ['Kitchener', 'Waterloo', 'Cambridge', 'Guelph', 'Brantford'],
    },

    edmonton: {
        locationId: 13337,
        locationName: 'Edmonton',
        badgeGroup: 'alberta',
        cities: ['Edmonton', 'Sherwood Park', 'St. Albert', 'Spruce Grove', 'Leduc', 'Fort Saskatchewan'],
    },

    ottawa: {
        locationId: 15304,
        locationName: 'Ottawa',
        badgeGroup: 'quebec',
        cities: ['Ottawa', 'Gatineau'],
    },

    hamilton: {
        locationId: 35818,
        locationName: 'Hamilton',
        badgeGroup: 'ontario',
        cities: ['Hamilton', 'Burlington', 'Oakville', 'Milton', 'Halton Hills'],
    },

    niagara: {
        locationId: 35818,
        locationName: 'Niagara',
        badgeGroup: 'ontario',
        cities: ['St. Catharines', 'Niagara Falls', 'Welland', 'Fort Erie', 'Grimsby', 'Lincoln', 'Niagara-on-the-Lake', 'Pelham', 'Port Colborne', 'Thorold', 'Wainfleet', 'West Lincoln'],
    },
    
    houston: {
        locationId: 35818,
        locationName: 'Houston',
        badgeGroup: 'texas',
    },

    sanantonio: {
        locationId: 19493,
        locationName: 'San Antonio',
        badgeGroup: 'texas',
        cities: ['San Antonio', 'New Braunfels', 'Seguin', 'Boerne', 'Schertz', 'Cibolo', 'Converse', 'Universal City', 'Live Oak', 'Selma', 'Alamo Heights', 'Balcones Heights', 'Castle Hills', 'China Grove', 'Elmendorf', 'Fair Oaks Ranch', 'Grey Forest', 'Helotes', 'Hill Country Village', 'Hollywood Park', 'Kirby', 'Leon Valley', 'Olmos Park', 'Shavano Park', 'Somerset', 'St. Hedwig', 'Terrell Hills', 'Windcrest'],
    },

    barrie: {
        locationId: 16243,
        locationName: 'Barrie',
        badgeGroup: 'ontario',
        cities: ['Barrie', 'Orillia', 'Midland', 'Collingwood', 'Wasaga Beach'],
    },
};

export default cityConfig;