import React from 'react';
import { Link } from 'react-router-dom';
import './AboutUs.css'; 
import aboutUs from '../../assets/images/resources/ultamovers-movers.png';

const AboutUs = ({ cityData }) => {
    return (
        <section className="about-us-section">
            <div className="about-us-container"> {/* Inner container */}
                {/* Left Column */}
                <div className="about-us-content">
                    <h2 className="about-us-title">
                        Serving {cityData.locationName} and more!
                    </h2>
                    <p className="about-us-description">
                        Let us take the stress out of your move. 
                        <br /><br />
                        We are happy to help with packing, moving, and junk removal. 
                    </p>
                    <Link 
                        to="#landing-page-form" 
                        className="about-us-button"
                        style={{
                            backgroundColor: "rgb(251, 225, 8)",
                            color: "#22448F",
                            padding: "15px 30px",
                            borderRadius: "5px",
                            fontSize: "24px",
                            fontWeight: 700,
                            fontFamily: "'Roboto', sans-serif",
                            display: "inline-block",
                            textDecoration: "none",
                            width: "350px",
                        }}
                    >
                        Get Free Moving Quote
                    </Link>
                </div>

                {/* Right Column */}
                <div className="about-us-image-container">
                    <img
                        src={aboutUs}
                        className="about-us-image"
                        alt='Ulta Movers'
                    />
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
