import React, { useState, useEffect } from 'react';
import './ExitIntentPopup.css'; // External CSS for better control

const ExitIntentPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Delay the execution by 5 seconds
    const timer = setTimeout(() => {
      // Check if popup has already been shown in this session
      if (sessionStorage.getItem('exitIntentPopupShown')) return;

      // Check the current URL to prevent the popup on certain pages
      const currentURL = window.location.href.toLowerCase();
      const blockedURLs = ['thanks', 'thank-you', 'confirmation', 'confirm'];
      const isBlockedPage = blockedURLs.some((keyword) => currentURL.includes(keyword));
      
      if (!isBlockedPage) {
        const handleMouseMove = (event) => {
          const { clientY } = event;
          
          // Double-check session storage here to avoid multiple triggers
          if (clientY <= 10 && !sessionStorage.getItem('exitIntentPopupShown')) {
            setShowPopup(true);
            sessionStorage.setItem('exitIntentPopupShown', 'true'); // Mark as shown
          }
        };
    
        // Add mouse movement event listener
        document.addEventListener('mousemove', handleMouseMove);
    
        return () => {
          // Clean up the event listener on component unmount
          document.removeEventListener('mousemove', handleMouseMove);
        };
      }
    }, 5000); // 5-second delay

    // Cleanup the timer if component unmounts before 5 seconds
    return () => clearTimeout(timer);
  }, []);  

  const handleClose = () => {
    setShowPopup(false); // Close the popup
  };

  return (
    <>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h1 className="headline">Are you leaving without getting a free quote?</h1>
            <p className="subheadline">🔥 Pro Tip: <strong>Save money by booking ahead to ensure a stress-free move.</strong> 
            <br />
            - UltaMovers Team
            </p>
            <button className="cta-button" onClick={handleClose}>
              Got it! Thanks!
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ExitIntentPopup;
