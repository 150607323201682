import React from 'react';
import packer from '../../assets/images/resources/ultamovers-packing.png';
import mover from '../../assets/images/resources/ultamovers-moving.png';
import junker from '../../assets/images/resources/ultamovers-junk-removal.png';

const ServiceCard = ({ title, description, imageSrc }) => {
    return (
        <div className='row' style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '60px 20px', 
            position: 'relative', // Add position relative to handle absolute positioning of the yellow background
        }}>
            {/* Yellow background div */}
            <div style={{
                position: 'absolute',
                // backgroundColor: 'rgb(251, 225, 8)',
                // width: '150px', 
                height: '150px', 
                maxWidth: '195px', 
                top: '72px', // Adjusts how far the background shifts down
                left: '165px', // Adjusts how far the background shifts right
                zIndex: 0, // Make sure the background is behind the image
            }} />

            {/* Image element */}
            <img 
                src={imageSrc} 
                alt={title} 
                style={{
                    width: '100%', 
                    height: 'auto', 
                    maxWidth: '300px', 
                    marginBottom: '10px',
                    position: 'relative',
                    zIndex: 1, // Ensure the image is above the yellow background
                }} 
            />

            {/* Title element */}
            <h3 style={{
                color: "#4E4E4E",
                fontFamily: "'Oswald', sans-serif",
                fontSize: "22px", 
                fontWeight: 600,
                textTransform: "uppercase",
                margin: '10px 0', 
                textAlign: 'center',
                zIndex: 1, // Ensures the title is above the yellow background
            }}>
                {title}
            </h3>

            {/* Description element */}
            <p style={{
                fontSize: '1.19rem', 
                color: '#555',
                textAlign: 'center',
                lineHeight: '1.4', 
                margin: '0',
                fontWeight: 400,
                zIndex: 1, // Ensures the description is above the yellow background
            }}>
                {description}
            </p>
        </div>
    );
};

const ServicesSection = () => {
    const services = [
        {
            title: "Packing & Unpacking",
            description: "We carefully pack and unpack your belongings for a stress-free move.",
            imageSrc: packer,
        },
        {
            title: "Moving",
            description: "You can trust that we'll handle your move with care when loading, unloading and on the road.",
            imageSrc: mover,
        },
        {
            title: "Junk Removal",
            description: "There's no better time to dispose of bulky items. Don't take it with you - let us haul it off for you.",
            imageSrc: junker,
        },
    ];

    return (
        <section style={{ padding: '20px', backgroundColor: '#F9F9F9', margin: '0' }}>
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                gap: '0px',
                maxWidth: '1200px',
                margin: '0 auto',
            }}>
                {services.map((service, index) => (
                    <ServiceCard 
                        key={index} 
                        title={service.title} 
                        description={service.description} 
                        imageSrc={service.imageSrc} 
                    />
                ))}
            </div>
        </section>
    );
};

export default ServicesSection;
