import React from 'react';
import { Link } from 'react-router-dom'; 
import rateBanner from '../../assets/images/mascots/happy-ulta.png';

function HowItWorksSection({ cityData, locationInfo }) {

    return (
        <section className="why-choose-one">
            <div className="container"
                style={{
                    padding: '20px 40px',
                }}
            >
                <div className="row">
                    <div className="col-xl-4 col-lg-5">
                        <div className="why-choose-one__left">
                            <div className="why-choose-one__img-box">
                                <div className="why-choose-one__img">
                                    <img src={rateBanner} alt="" />
                                </div>
                                {/* <div className="why-choose-one__trusted">
                                    <div className="why-choose-one__trusted-icon">
                                        <span className="icon-reliability"></span>
                                    </div>
                                    <div className="why-choose-one__trusted-content">
                                        <h3
                                            style={{
                                                lineHeight: "1.4em",
                                            }}
                                        >
                                            Serving {cityData.locationName} and beyond!
                                        </h3>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-7">
                        <div className="why-choose-one__right">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">HOW IT WORKS</span>
                                <h2 className="section-title__title">Moving made easy 💪</h2>
                            </div>
                            <ul className="list-unstyled why-choose-one__points">
                                {/* <h4>Just 3 steps:</h4>
                                <br /> */}
                                <li>
                                    <div className="icon">
                                        <span className="icon-comment"></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Call and tell us:&nbsp; what, where and when you're moving.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="icon-comment"></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Digitally sign the inventory/contract and pay the deposit.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <ul className="list-unstyled why-choose-one__points">
                                <Link 
                                    to={'tel:'+locationInfo.tracking_phone}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'rgb(251, 225, 8)',
                                            color: 'rgb(34, 68, 143)',
                                            padding: '15px 30px',
                                            borderRadius: '5px',
                                            fontWeight: '700',
                                            fontFamily: 'Roboto, sans-serif',
                                            display: 'inline-block',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        Call today: {locationInfo.tracking_phone}
                                    </p>
                                </Link>
                                <br /><br />
                                <h4>
                                    Just 2 steps and you're done! 
                                </h4>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowItWorksSection;