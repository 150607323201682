import React from 'react';

const Step5 = ({ prevStep, formData = {}, formik }) => {
  const { name = '', phone = '', email = '' } = formData; // Provide default values
  const { values, handleChange } = formik;
  const priceRange = values.rooms * 299;
  const toPriceRange = values.rooms * 399;
  const distanceRate = values.distanceInMiles < 100
    ? 1.0
    : values.distanceInMiles > 100 && values.distanceInMiles <= 400 && values.startState === values.destinationState
    ? 2.25
    : 3.5;

  // Formatting the costs without showing cents (removing decimals)
  const estimatedCostStart = Math.floor(distanceRate * priceRange).toLocaleString();
  const estimatedCostEnd = Math.floor(distanceRate * toPriceRange).toLocaleString();

  return (
    <div className="wizard-step">
      <h2>Save your quote!</h2>

      <p>
        Type of Move:&nbsp;
        <span
          style={{
            fontWeight: 'bold',
            color: 
              values.distanceInMiles < 100 ? 'green' :
              values.distanceInMiles > 100 && values.distanceInMiles <= 400 && values.startState === values.destinationState ? 'blue' :
              values.distanceInMiles > 100 && values.distanceInMiles <= 400 && values.startState !== values.destinationState ? 'purple' :
              'black',
          }}
        >
            {values.distanceInMiles < 100 ? 'Local' :
              values.distanceInMiles > 100 && values.distanceInMiles <= 400 && values.startState === values.destinationState ? 'Intrastate (Short Distance)' :
              values.distanceInMiles > 100 && values.distanceInMiles <= 400 && values.startState !== values.destinationState ? 'Interstate (Short Distance)' :
              'Long Distance'
            }
          </span>
      </p>
      <br />
      <p>Estimated moving cost starts between&nbsp;
        <span 
          style={{ fontWeight: 'bold' }}
        > 
          ${estimatedCostStart} to ${estimatedCostEnd}.
        </span>
      </p>
      <p>Quote expires in &nbsp;
        <span 
          style={{ fontWeight: 'bold' }}
        > 
          24-hours.
        </span> Save it by filling out the form below.
      </p>
      <br />

      {/* <p>Distance in miles: {values.distanceInMiles ? values.distanceInMiles.toFixed(2) : 'Calculating...'}</p> */}

      <label>Name:</label>
      <input type="text" value={name} onChange={handleChange} name="name" />

      <label>Phone:</label>
      <input type="tel" value={phone} onChange={handleChange} name="phone" />

      <label>Email:</label>
      <input type="email" value={email} onChange={handleChange} name="email" />

      <div className="button-container">
        <button className="outline-button" onClick={prevStep}>Back</button>
        <button className="next-button" type="submit">Save Quote!</button>
      </div>
    </div>
  );
};

export default Step5;
