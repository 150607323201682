import React from 'react';
import mascot from '../../assets/images/mascots/mascot-love.png';
import HeroFormLander from '../Landing/HeroFormLander';

function RequestQuote({ cityData, locationInfo }) {

    return (
        <section className="why-choose-one">
            <div className="container">
                <div className="row">
                    <div className="section-title text-center">
                        <h2 
                            className="section-title__title "
                            style={{
                                color: 'rgb(78, 78, 78)',
                            }}
                        >
                            Complete the form below to get a quote from one of our friendly moving coordinators:
                        </h2>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="why-choose-one__left">
                            <div className="why-choose-one__img-box">
                                <div className="why-choose-one__img" style={{ textAlign: 'center' }}>
                                    <img 
                                        src={mascot} 
                                        alt="" 
                                        style={{
                                            width: "60%",
                                            maxWidth: "400px",
                                        }}
                                    />
                                </div>
                                {/* <div className="why-choose-one__trusted">
                                    <div className="why-choose-one__trusted-icon">
                                        <span className="icon-reliability"></span>
                                    </div>
                                    <div className="why-choose-one__trusted-content">
                                        <h3
                                            style={{
                                                lineHeight: "1.4em",
                                            }}
                                        >
                                            Serving {cityData.locationName} and beyond!
                                        </h3>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                        <HeroFormLander 
                            locationInfo={locationInfo}
                            cityData={cityData}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RequestQuote;