import React from 'react';
import { Link } from 'react-router-dom';

function Footer({ cityData, locationInfo }) {
    return (
        <footer className="site-footer">
            <div className="site-footer__bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="site-footer__bottom-inner">
                                <p className="site-footer__bottom-text">
                                    © <Link to="">UltaMovers</Link>
                                </p>
                                <ul className="site-footer__bottom-text-two list-unstyled">
                                    <li>
                                        <Link to="privacy">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.chizebrands.com">CHIZE Brands</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;