import React from 'react';
import './Wizard.css';

const ProgressBar = ({ step }) => {
  const progressPercentage = (step / 5) * 100;

  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
    </div>
  );
};

export default ProgressBar;
